<template>
	<v-container fluid v-cloak>
		<Contratos
			class="pa-0"
			v-if="$route.query.codigoContrato"
			:key="loading"
			:codigoContrato="$route.query.codigoContrato"
		/>

		<div class="d-flex mt-4 align-center">
			<v-btn small to="/contratos" color="primary">
				<v-icon small left>mdi-arrow-left</v-icon>Volver a contratos
			</v-btn>
			<v-btn class="ml-1" small @click.stop="getIncidencias" color="primary">
				<v-icon small left>mdi-reload</v-icon>Recargar
			</v-btn>
			<v-btn
				class="ml-1"
				small
				@click="crear = true"
				color="primary"
				v-if="$route.query.codigoContrato && $root.acceso('CREAR_INCIDENCIAS')"
				:disabled="abierta"
			>
				<v-icon small left>mdi-plus</v-icon>Crear Incidencia
			</v-btn>

			<v-btn
				v-for="filtro in !$route.query.codigoContrato ? Object.keys(estadosFiltros) : []"
				:key="filtro"
				small
				text
				:class="{ 'ma-1': true, 'v-btn--active': estadosFiltros[filtro] }"
				:color="getSituacionIcon(filtro).color"
				@click.stop="estadosFiltros[filtro] = !estadosFiltros[filtro]"
				:disabled="incidencias.filter(imp => imp.estado == filtro).length == 0"
			>
				<v-icon left>{{ getSituacionIcon(filtro).icon }}</v-icon>
				{{ incidencias.filter(imp => imp.estado == filtro).length }} {{ filtro }}
			</v-btn>
		</div>

		<div class="d-flex mt-2">
			<v-alert
				text
				type="info"
				dense
				v-if="abierta && $route.query.codigoContrato && $root.acceso('CREAR_INCIDENCIAS')"
			>No puedes crear una incidencia habiendo una abierta</v-alert>
		</div>

		<v-subheader class="mt-2">Incidencias</v-subheader>
		<v-card>
			<v-data-table
				fixed-header
				:items="incidenciasFiltradas"
				:headers="incidenciasHeader"
				:calculate-widths="true"
				checkbox-color="secondary"
				:loading="loading"
				:footer-props="{
					showFirstLastPage: true,
					showCurrentPage: true,
					itemsPerPageOptions: [10, 25, 50],
				}"
				show-select
				class="fixed-checkbox"
				:single-select="false"
				item-key="idIncidencia"
			>
				<template v-slot:body.prepend="{ headers }">
					<table-filters :headers="headers" :items="incidenciasFiltradas" v-model="inlineFilters"></table-filters>
				</template>

				<template v-slot:item.codigoContrato="{ item }">
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								v-on="on"
								v-bind="attrs"
								link
								:to="`/contratos/detalles?codigoContrato=${item.codigoContrato}&idContrato=${item.idContrato}`"
								color="secondary"
								text
							>{{ Math.abs(Number(item.codigoContrato)) }}</v-btn>
						</template>
						<span>Detalles del contrato</span>
					</v-tooltip>
				</template>

				<template v-slot:item.estado="{ item }">
					<status-chip :value="item.estado" />
				</template>

				<template v-slot:item.fecha="{ item }">
					<span v-text="parseDate(item.fecha * 1000)" />
				</template>

				<template v-slot:item.empresa="{ item }">
					<empresa-chip v-if="item.empresa" :value="item.empresa" />
				</template>

				<template v-slot:item.mensaje="{ item }">
					<span
						class="d-inline-block text-truncate"
						style="max-width: 400px; max-height: 17px"
						v-html="item.mensaje"
					/>
				</template>

				<template v-slot:item.acciones="{ item }">
					<v-btn
						color="primary"
						small
						outlined
						@click="
	idIncidencia = item.id;
codigoContrato = item.codigoContrato;
idContrato = item.idContrato;
						"
					>
						<v-icon left small>mdi-eye</v-icon>ver detalles
					</v-btn>
				</template>
			</v-data-table>
			<!-- <portal-target name="filter-btn" /> -->
		</v-card>

		<v-subheader class="mt-2">Impagos</v-subheader>

		<v-btn
			v-if="$root.acceso('CONTROL_IMPAGADOS') && !$route.query.codigoContrato && !idIncidencia"
			small
			color="primary"
			class="my-1"
			to="/impagados/control"
		>ACCEDER AL CONTROL DE IMPAGOS</v-btn>

		<v-card v-else>
			<v-data-table
				fixed-header
				:items="impagos"
				:headers="impagosHeader"
				:calculate-widths="true"
				checkbox-color="secondary"
				:loading="loading"
				:footer-props="{
					showFirstLastPage: true,
					showCurrentPage: true,
					itemsPerPageOptions: [10, 25, 50],
				}"
				show-select
				class="fixed-checkbox"
				:single-select="false"
				item-key="IdCarteraCobro"
			>
				<template v-slot:body.prepend="{ headers }">
					<table-filters :headers="headers" :items="impagos" v-model="inlineFiltersImp"></table-filters>
				</template>

				<template v-slot:item.CodigoContrato="{ item }">
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								v-on="on"
								v-bind="attrs"
								link
								:to="`/contratos/detalles?codigoContrato=${item.CodigoContrato}&idContrato=${item.IdContrato}`"
								color="secondary"
								text
							>{{ Math.abs(Number(item.CodigoContrato)) }}</v-btn>
						</template>
						<span>Detalles del contrato</span>
					</v-tooltip>
				</template>

				<template v-slot:item.Identificador="{ item }">
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								v-on="on"
								v-bind="attrs"
								link
								:to="`/clientes/detalles?idCliente=${item.IdCliente}`"
								color="secondary"
								text
							>{{ item.Identificador }}</v-btn>
						</template>
						<span>Detalles del cliente</span>
					</v-tooltip>
				</template>

				<template v-slot:item.estado="{ item }">
					<status-chip :value="item.estado" />
				</template>

				<template v-slot:item.FechaImpagado="{ item }">
					<span v-text="parseDate(item.FechaImpagado)" />
				</template>

				<template v-slot:item.empresa="{ item }">
					<empresa-chip v-if="item.empresa" :value="item.empresa" />
				</template>

				<template v-slot:item.NumeroFactura="{ item }">
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								v-on="on"
								v-bind="attrs"
								link
								@click="verPDFfactura(item.IdDocumento)"
								color="secondary"
								text
								:disabled="!!pdfId || !item.IdDocumento"
								:loading="pdfId == item.IdDocumento && pdfId !== null"
							>
								{{ item.SerieFactura }} {{ item.NumeroFactura }}
								<v-icon
									right
								>{{ item.IdDocumento ? "mdi-file-document-edit-outline" : 'mdi-file-cancel-outline' }}</v-icon>
							</v-btn>
						</template>
						<span>Ver PDF factura</span>
					</v-tooltip>
				</template>

				<template v-slot:item.NumeroFacturaRoot="{ item }">
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								v-on="on"
								v-bind="attrs"
								link
								@click="verPDFfactura(item.IdDocumento)"
								color="secondary"
								text
								:disabled="!!pdfId || !item.IdDocumento"
								:loading="pdfId == item.IdDocumento && pdfId !== null"
							>
								{{ item.SerieFacturaRoot }} {{ item.NumeroFacturaRoot }}
								<v-icon
									right
								>{{ item.IdDocumento ? "mdi-file-document-edit-outline" : 'mdi-file-cancel-outline' }}</v-icon>
							</v-btn>
						</template>
						<span>Ver PDF factura</span>
					</v-tooltip>
				</template>

				<template v-slot:item.mensaje="{ item }">
					<span
						class="d-inline-block text-truncate"
						style="max-width: 400px; max-height: 17px"
						v-html="item.mensaje"
					/>
				</template>

				<template v-slot:item.acciones="{ item }">
					<v-btn color="primary" small outlined @click="detailsId = item.IdCarteraCobro">
						<v-icon left small>mdi-eye</v-icon>ver detalles
					</v-btn>
				</template>
			</v-data-table>
			<!-- <portal-target name="filter-btn" /> -->
		</v-card>

		<v-dialog
			:value="detailsId"
			@input="(v) => v === false ? detailsId = null : null"
			fullscreen
			hide-overlay
			transition="dialog-bottom-transition"
		>
			<v-card>
				<v-toolbar dense color="primary">
					<v-toolbar-title class="white--text">Detalles impago Cod {{ detailsId }}</v-toolbar-title>
					<v-spacer></v-spacer>

					<v-btn color="white" icon @click="detailsId = null">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-toolbar>

				<v-tabs v-model="tab">
					<v-tab>Control impago</v-tab>
					<!-- <v-tab v-if="$root.acceso('CONTROL_IMPAGADOS')">Histórico llamadas</v-tab> -->
					<v-tab>Detalles factura</v-tab>
					<v-tab v-if="$root.acceso('CONTROL_IMPAGADOS')">Avisos formales</v-tab>
				</v-tabs>

				<v-tabs-items v-model="tab">
					<v-tab-item>
						<detalles-impagados
							@changeIdCarteraCobro="v => detailsId = v"
							@close="detailsId = null"
							:IdCarteraCobro="detailsId"
							v-if="detailsId"
							:key="detailsId"
						/>
					</v-tab-item>
					<!-- <v-tab-item>
						<historico-llamadas
							@close="detailsId = null"
							:IdCarteraCobro="detailsId"
							v-if="detailsId"
							:key="detailsId"
						/>
					</v-tab-item>-->
					<v-tab-item>
						<detalles-factura
							@close="detailsId = null"
							:idFactura="idCabeceraAux"
							:IdCarteraCobro="detailsId"
							v-if="idCabeceraAux"
							:key="idCabeceraAux"
						/>
					</v-tab-item>
					<v-tab-item>
						<avisos-formales
							@close="detailsId = null"
							:IdCarteraCobro="detailsId"
							v-if="idCabeceraAux"
							:key="idCabeceraAux"
						/>
					</v-tab-item>
				</v-tabs-items>
			</v-card>
		</v-dialog>

		<v-dialog
			transition="dialog-bottom-transition"
			fullscreen
			:value="idIncidencia != null"
			@input="(v) => v === false ? idIncidencia = null : null"
		>
			<detalles-incidencia
				@close="
	idIncidencia = null;
getIncidencias;
				"
				:key="idIncidencia"
				:idIncidencia="idIncidencia"
				:codigoContrato="codigoContrato"
				:idContrato="idContrato"
				@reload="
	getIncidencias();
getContrato();
				"
			/>
		</v-dialog>

		<v-dialog persistent v-model="crear" max-width="800px" v-if="$root.acceso('CREAR_INCIDENCIAS')">
			<v-card>
				<v-toolbar dense :elevation="0" color="primary">
					<v-toolbar-title class="white--text">
						Crear incidencia para el contrato
						{{ $route.query.codigoContrato }}
					</v-toolbar-title>
					<v-spacer></v-spacer>
					<v-btn icon color="white" @click="crear = false">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-toolbar>
				<v-form @submit.prevent="crearIncidencia" class="pa-4" ref="form">
					<v-container fluid class="pa-2 mt-2">
						<h3 class="text-h6 mb-4">Escribe aquí el primer mensaje de la incidencia:</h3>
						<v-row>
							<v-col cols="12">
								<text-editor
									v-model="mensaje"
									:error="emptyMsg"
									@input="(v) => (emptyMsg = !v)"
									class="mb-2"
								/>
							</v-col>
							<v-col cols="12">
								<div class="d-flex">
									<v-spacer />
									<v-btn outlined color="primary" @click="crearIncidencia">
										<v-icon left>mdi-content-save</v-icon>Crear Incidencia
									</v-btn>
								</div>
							</v-col>
						</v-row>
					</v-container>
				</v-form>
			</v-card>
		</v-dialog>
	</v-container>
</template>

<script>
import { perColumnFilter, parseDate, decode64, downloadFile, getSituacionIcon } from "@/utils/index.js";
import { req } from "@/utils/validations.js";

export default {
	components: {
		DetallesIncidencia: () => import("../views/DetallesIncidencia.vue"),
		TableFilters: () => import("@/components/TableFilters.vue"),
		TextEditor: () => import("@/components/TextEditor.vue"),
		StatusChip: () => import("@/components/StatusChip.vue"),
		EmpresaChip: () => import("@/components/EmpresaChip.vue"),
		Contratos: () => import("@/modules/contratos/views/Contratos.vue"),
		DetallesFactura: () => import("@/modules/facturacion/views/DetallesFactura.vue"),
		DetallesImpagados: () => import("@/modules/impagados/views/DetallesImpagados.vue"),
		HistoricoLlamadas: () => import("@/modules/impagados/views/HistoricoLlamadas.vue"),
		AvisosFormales: () => import("@/modules/impagados/views/AvisosFormales.vue"),
	},
	data() {
		return {
			idIncidencia: this.$route.query.idIncidencia || null,
			codigoContrato: null,
			idContrato: null,
			loading: false,
			crear: false,
			detailsId: null,
			tab: 0,
			incidencias: [],
			impagos: [],
			mensaje: "",
			emptyMsg: false,
			rules: {
				req,
			},
			inlineFilters: {},
			inlineFiltersImp: {},
			incidenciasHeader: [
				{ text: "Número", value: "id" },
				{ text: "Contrato", value: "codigoContrato" },
				{ text: "Fecha", value: "fecha", dataType: "date" },
				{ text: "Motivo", value: "mensaje" },
				{ text: "Estado", value: "estado", dataType: "select" },
				{
					text: "Acciones",
					value: "acciones",
					sortable: false,
					filterable: false,
				},
			].map((header) => {
				return {
					class: "text-no-wrap sticky-header",
					cellClass: "pa-2 text-no-wrap",
					filter: (value) =>
						perColumnFilter(
							value,
							this.inlineFilters[header.value],
							header.dataType
						),
					dataType: "text",
					...header,
				};
			}),
			impagosHeader: [
				{ text: "Estado", value: "estado", dataType: 'select' },
				{ text: "Factura", value: "NumeroFactura" },
				{ text: "Factura original", value: "NumeroFacturaRoot" },
				{ text: "Contrato", value: "CodigoContrato" },
				{ text: "DNI/NIE", value: "Identificador" },
				{ text: "Fecha Impagado", value: "FechaImpagado", dataType: "date" },
				{ text: "Motivo", value: "motivoImpagado" },
				{ text: "Acciones", value: "acciones" },
			].map((header) => {
				return {
					class: "text-no-wrap sticky-header",
					cellClass: "pa-2 text-no-wrap",
					filter: (value) =>
						perColumnFilter(
							value,
							this.inlineFiltersImp[header.value],
							header.dataType
						),
					dataType: "text",
					...header,
				};
			}),

			estadosFiltros: {
				'Cerrada': true
			},

			pdfId: null
		};
	},
	mounted() {
		this.getIncidencias();
	},
	computed: {
		abierta() {
			return this.incidencias.some((i) => i.estado == "Abierta");
		},
		idCabeceraAux() {
			return (this.impagos.find(x => x.IdCarteraCobro == this.detailsId) || {}).IdFacturaVentaCabecera
		},
		incidenciasFiltradas() {
			return this.incidencias.filter((inc) => {
				console.log(inc)
				for (let [key, value] of Object.entries(this.estadosFiltros)) {
					console.log({key, value})
					if (inc.estado == key) return value;
				}
				return true;
			})
		}
	},
	methods: {
		getSituacionIcon,
		parseDate,
		decode64,
		getIncidencias() {
			this.loading = true;
			let str = this.$store.getters.getDatosEmpresa;
			const { IdAgente } = str.iss;
			axios({
				method: "GET",
				url: `${process.env.VUE_APP_OUR_API_URL}/incidencias.php`,
				params: {
					token: this.$store.getters.getJwtEmpresa,
					codigoContrato: this.$route.query.codigoContrato,
					idAgente: IdAgente,
				},
			}).then(({ data }) => {
				this.incidencias = data.incidencias;
				this.impagos = data.impagos;
				this.loading = false;
			}).catch(err => console.error(err));
		},
		async crearIncidencia() {
			if (this.mensaje.length == 0) {
				this.emptyMsg = true;
				return;
			}
			if (!this.$refs.form.validate()) return;

			let str = this.$store.getters.getDatosEmpresa;
			const { IdAgente } = str.iss;

			const { data: contratos } = await axios({
				method: "GET",
				url: `${process.env.VUE_APP_OUR_API_URL}/contratosVerificados.php`,
				params: {
					token: this.$store.getters.getJwtEmpresa,
					IdAgente,
					codigoContrato: this.$route.query.codigoContrato,
				},
			})

			axios({
				method: "POST",
				url: `${process.env.VUE_APP_OUR_API_URL}/incidencias.php`,
				data: {
					token: this.$store.getters.getJwtEmpresa,
					codigoContrato: this.$route.query.codigoContrato,
					empresa: JSON.parse(
						decode64(this.$store.getters.getJwtEmpresa.split(".")[1])
					).empresa,
					mensaje: this.mensaje,
					idAgente: Array.isArray(contratos) ? contratos[0].IdAgente : contratos.IdAgente,
				},
			})
				.then((res) => {
					this.$root.$emit("snack", "Se ha creado la incidencia con éxito");
					this.crear = false;
					// this.getContrato();
					this.getIncidencias();
				})
				.catch((err) => {
					console.error(err);
					this.$root.$emit("snack", "Ha ocurrido un error");
				});
		},
		verPDFfactura(idDocumento) {
			this.pdfId = idDocumento
			let str = this.$store.getters.getDatosEmpresa;
			const { IdUsuario } = str.iss;
			const IdEmpresa = this.$store.getters.getIdEmpresa;
			axios({
				method: "get",
				url: `${process.env.VUE_APP_API_URL}/FacturaVentaCabecera/FacturaPreviewPDF/Empresa/${IdEmpresa}/Usuario/${IdUsuario}/Documento/${idDocumento}`,
				responseType: "blob",
			})
				.then((res) => {
					let pdf = new File(
						[res.data],
						`factura_${new Date().getTime()}.pdf`,
						{
							type: "application/pdf",
						}
					);
					downloadFile(pdf, true);
					this.pdfId = null
				})
				.catch((err) => {
					this.$root.$emit("snack", "Ha ocurrido un error");
					this.pdfId = null
				});
		},
	},
};
</script>

<style scoped>
.v-data-table /deep/ [role="columnheader"] {
	/* background: #f1f1f1 !important; */
}
</style>